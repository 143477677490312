import { template as template_2981d2a73c8e4da18f254da959d02104 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import icon from "discourse-common/helpers/d-icon";
export default class FKErrors extends Component {
    concatErrors(errors) {
        return errors.join(", ");
    }
    static{
        template_2981d2a73c8e4da18f254da959d02104(`
    <p class="form-kit__errors" id={{@id}} aria-live="assertive" ...attributes>
      <span>
        {{icon "triangle-exclamation"}}
        {{this.concatErrors @error.messages}}
      </span>
    </p>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
