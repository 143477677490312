import { template as template_b3a6f481ff684e3abd226df1e45e2953 } from "@ember/template-compiler";
import i18n from "discourse-common/helpers/i18n";
import SectionLink from "../section-link";
const SidebarCommonAllTagsSectionLink = template_b3a6f481ff684e3abd226df1e45e2953(`
  <SectionLink
    @linkName="all-tags"
    @content={{i18n "sidebar.all_tags"}}
    @route="tags"
    @prefixType="icon"
    @prefixValue="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarCommonAllTagsSectionLink;
