import { template as template_48eba8c392e14732b2020ca39c799e75 } from "@ember/template-compiler";
const FKControlMenuContainer = template_48eba8c392e14732b2020ca39c799e75(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
